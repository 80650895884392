<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <b-sidebar
      type="is-light"
      :fullheight="isFullheight"
      :overlay="isOverlay"
      :right="isRight"
      :open.sync="open"
      :on-cancel="closeSideBar"
      :can-cancel="['escape', 'x']"
    >
      <div class="p-0 form">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(confirmSave)">
            <div class="columns is-multiline">
              <div class="column is-10">
                <h1 class="form-title" v-if="!questionId">Create Question</h1>
                <h1 class="form-title" v-else>Update Question</h1>
              </div>
              <div class="column is-2">
                <b-button
                  class="button-close-top"
                  style="float:right"
                  @click="closeSideBar()"
                >
                  Close
                </b-button>
              </div>

              <!-- Question Section -->
              <div class="column is-2 question-description">
                Question Name
                <b-tooltip type="is-danger" label="This field is required">
                  <span class="required">*</span>
                </b-tooltip>
              </div>
              <div class="column is-10">
                <b-field>
                  <b-input v-model="question.name" required></b-input>
                </b-field>
              </div>

              <div class="column is-12">
                <div class="columns is-multiline">
                  <div class="column is-2 question-description">
                    Question Type
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <b-select required expanded v-model="question.questionType">
                      <option value="essay">Essay</option>
                      <option value="radio button">Radio Button</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="linear scale">Linear Scale</option>
                      <option value="ranking">Ranking</option>
                      <option value="star rating">Star Rating</option>
                      <option value="matrix">Matrix</option>
                    </b-select>
                  </div>

                  <div class="column is-2 question-description">
                    Level
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <b-select
                      required
                      expanded
                      v-model="question.selectedLevel"
                    >
                      <option
                        v-for="level in data.levels"
                        :value="level.id"
                        :key="level.id"
                      >
                        {{ level.name }}
                      </option>
                    </b-select>
                  </div>

                  <div class="column is-2 question-description">
                    Category
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      :vid="`category`"
                      name="Category"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-taginput
                          v-model="question.selectedCategory"
                          :data="data.categories"
                          :open-on-focus="false"
                          :loading="isFetchingCategory"
                          :allow-new="true"
                          autocomplete
                          field="name"
                          maxtags="1"
                          placeholder="Type to search category"
                          @typing="getCategory"
                        >
                          <template slot="empty">
                            Press Enter to add new Category
                          </template>
                        </b-taginput>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="column is-2 question-description">
                    Add Tag
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field :type="errors.length > 0 ? 'is-danger' : null">
                          <b-taginput
                            v-model="question.newTags"
                            :data="searchTags"
                            :open-on-focus="false"
                            :loading="isFetching"
                            autocomplete
                            placeholder="Enter The Tags"
                            :allow-new="true"
                            @typing="getTags"
                          >
                            <template slot="empty">Press Enter to add new Tag</template>
                          </b-taginput>
                        </b-field>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column is-2 question-description">
                    Question
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field :type="errors.length > 0 ? 'is-danger' : null">
                          <ckeditor
                            v-model="question.question"
                            :config="editorConfig.config1"
                          />
                        </b-field>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column is-2 question-description">
                    Has Image?
                  </div>
                  <div class="column is-10">
                    <b-switch
                      class="mb-3"
                      v-model="question.hasImage"
                      type="is-hcc-create-question"
                    ></b-switch>
                  </div>

                  <div class="column is-12" v-if="question.hasImage">
                    <span
                      v-for="(image, indexImage) in question.images"
                      :key="indexImage"
                    >
                      <span v-if="question.hasImage">
                        <div class="columns is-multiline">
                          <div class="column is-12" v-if="image.selectedImage">
                            <span>
                              <strong>Image {{ indexImage + 1 }}</strong>
                            </span>
                            <br />
                            <span v-show="false">{{ image.createdAt }}</span>

                            <img
                              v-lazy="image.selectedImage"
                              style="max-height: 300px; max-width: 300px;"
                              v-if="question.hasImage"
                              alt="Question Image"
                            />
                          </div>
                          <div class="column is-2">
                            <b-upload
                              v-model="image.uploadedImage"
                              @input="getImage($event, indexImage)"
                              accept="image/*"
                              type="is-hcc-create-question"
                            >
                              <a
                                class="button is-hcc-create-question is-fullwidth"
                              >
                                <b-icon icon="upload"></b-icon>
                                <span>Upload</span>
                              </a>
                            </b-upload>
                          </div>
                          <div class="column is-4">
                            <b-icon
                              pack="far"
                              icon="plus-square"
                              type="is-hcc-create-question"
                              size="is-medium"
                              @click.native="addImage(indexImage)"
                            ></b-icon>
                            <b-icon
                              pack="far"
                              icon="minus-square"
                              type="is-hcc-create-question"
                              size="is-medium"
                              @click.native="removeImage(indexImage)"
                              v-if="question.images.length > 1"
                            ></b-icon>
                          </div>
                        </div>
                      </span>
                    </span>
                  </div>

                  <div class="column is-2 question-description">
                    Has Video?
                  </div>
                  <div class="column is-10">
                    <b-switch
                      class="mb-3"
                      v-model="question.hasVideo"
                      type="is-hcc-create-question"
                    ></b-switch>
                  </div>

                  <div class="column is-12" v-if="question.hasVideo">
                    <span
                      v-for="(video, indexVideo) in question.videos"
                      :key="indexVideo"
                    >
                      <span v-if="question.hasVideo">
                        <div class="columns is-multiline">
                          <div class="column is-12">
                            <span>
                              <strong>Video {{ indexVideo + 1 }}</strong>
                            </span>
                            <br />
                            <span v-show="false">{{ video.createdAt }}</span>

                            <video-player
                              class="video-player-box"
                              width="320"
                              height="240"
                              controls="false"
                              muted="false"
                              autoplay
                              :ref="'video_' + indexVideo"
                              :options="setOptions(video.selectedVideo)"
                              v-lazy="video.selectedVideo"
                            ></video-player>
                            <b-button
                              type="is-hcc"
                              @click="playVideo(indexVideo)"
                              style="margin-top:10px;"
                            >
                              Play
                            </b-button>
                          </div>
                          <div class="column is-2">
                            <b-upload
                              v-model="video.uploadedVideo"
                              @input="getVideo($event, indexVideo)"
                              accept="video/*"
                              type="is-hcc-create-question"
                            >
                              <a
                                class="button is-hcc-create-question is-fullwidth"
                              >
                                <b-icon icon="upload"></b-icon>
                                <span>Upload</span>
                              </a>
                            </b-upload>
                          </div>
                          <div class="column is-4 ">
                            <b-input
                              v-model="video.maxPlayVideo"
                              required
                              placeholder="Max Video Played"
                              type="number"
                            ></b-input>
                          </div>
                          <div class="column is-2">
                            <b-icon
                              pack="far"
                              icon="plus-square"
                              type="is-hcc-create-question"
                              size="is-medium"
                              @click.native="addVideo(indexVideo)"
                            ></b-icon>
                            <b-icon
                              pack="far"
                              icon="minus-square"
                              type="is-hcc-create-question"
                              size="is-medium"
                              @click.native="removeVideo(indexVideo)"
                              v-if="question.videos.length > 1"
                            ></b-icon>
                          </div>
                        </div>
                      </span>
                    </span>
                  </div>
                  <!-- <span v-if="question.hasVideo">
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <video
                            width="320"
                            height="240"
                            controls
                            v-lazy="selectedVideo"
                          ></video>
                        </div>
                        <div class="column is-2">
                          <b-upload
                            v-model="uploadedVideo"
                            @input="getVideo($event)"
                            accept="video/*"
                            type="is-hcc-create-question"
                          >
                            <a class="button is-hcc-create-question is-fullwidth">
                              <b-icon icon="upload"></b-icon>
                              <span>Upload</span>
                            </a>
                          </b-upload>
                        </div>
                        <div class="column is-6 ml-4">
                          <b-input
                            v-model="question.maxPlayVideo"
                            required
                            placeholder="Max Video Played"
                          ></b-input>
                        </div>
                      </div>
                    </span> -->

                  <div class="column is-2 question-description">
                    Is Example?
                  </div>
                  <div class="column is-10">
                    <b-field>
                      <b-switch
                        @input="closeAnswer()"
                        v-model="question.isExample"
                        type="is-hcc-create-question"
                      >
                        <span v-if="question.isExample">
                          Question tidak akan dinilai, karena hanya contoh soal
                        </span>
                        <span v-else>Question akan dinilai</span>
                      </b-switch>
                    </b-field>
                  </div>

                  <!-- button for checkbox or radio button -->
                  <div
                    class="column is-2 question-description"
                    v-if="
                      question.questionType == 'radio button' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    is Button?
                  </div>
                  <div
                    class="column is-10"
                    v-if="
                      question.questionType == 'radio button' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    <b-field>
                      <b-switch
                        v-model="question.answerOption.isButton"
                        type="is-hcc-create-question"
                      ></b-switch>
                    </b-field>
                  </div>

                  <div
                    class="column is-2 question-description"
                    v-if="
                      question.isExample == true &&
                        (question.questionType == 'radio button' ||
                          question.questionType == 'checkbox')
                    "
                  >
                    True Answer
                  </div>
                  <div
                    class="column is-10"
                    v-if="
                      question.isExample == true &&
                        (question.questionType == 'radio button' ||
                          question.questionType == 'checkbox')
                    "
                  >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <ckeditor
                          v-model="question.answerOption.trueAnswer"
                          :config="editorConfig.config2"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div
                    class="column is-2 question-description"
                    v-if="
                      question.isExample == true &&
                        (question.questionType == 'radio button' ||
                          question.questionType == 'checkbox')
                    "
                  >
                    False Answer
                  </div>
                  <div
                    class="column is-10"
                    v-if="
                      question.isExample == true &&
                        (question.questionType == 'radio button' ||
                          question.questionType == 'checkbox')
                    "
                  >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <ckeditor
                          v-model="question.answerOption.falseAnswer"
                          :config="editorConfig.config3"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <!-- star rating type -->
                  <!-- max star = rightSideNumber -->
                  <!-- value star = leftSideNumber -->

                  <div
                    class="column is-2 question-description"
                    v-if="question.questionType == 'star rating'"
                  >
                    Max Star
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div
                    class="column is-10"
                    v-if="question.questionType == 'star rating'"
                  >
                    <b-numberinput
                      v-model="question.answerOption.rightSideNumber"
                      type="is-hcc-create-question"
                      min="1"
                      max="5"
                      controls-position="compact"
                      @input="changeStar()"
                    ></b-numberinput>
                  </div>

                  <!-- linear scale type -->

                  <div
                    class="column is-12 question-description"
                    v-if="question.questionType == 'linear scale'"
                  >
                    From
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div
                    class="columns is-multiline ml-2 mt-1"
                    v-if="question.questionType == 'linear scale'"
                  >
                    <b-input
                      v-model="question.answerOption.leftSide"
                      class="mr-4 "
                      required
                    ></b-input>
                    <b-field>
                      <b-numberinput
                        v-model="question.answerOption.leftSideNumber"
                        type="is-hcc-create-question"
                        min="0"
                        max="1"
                        controls-position="compact"
                      ></b-numberinput>
                    </b-field>
                  </div>
                  <div
                    class="column is-12 question-description"
                    v-if="question.questionType == 'linear scale'"
                  >
                    To
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div
                    class="columns ml-2 mt-1"
                    v-if="question.questionType == 'linear scale'"
                  >
                    <b-input
                      v-model="question.answerOption.rightSide"
                      class="mr-4 "
                      required
                    ></b-input>
                    <b-field>
                      <b-numberinput
                        v-model="question.answerOption.rightSideNumber"
                        type="is-hcc-create-question"
                        min="2"
                        max="10"
                        controls-position="compact"
                      ></b-numberinput>
                    </b-field>
                  </div>

                  <!-- matrix type -->
                  <!-- matrix row -->
                  <div
                    class="column is-12"
                    v-if="question.questionType == 'matrix'"
                  >
                    <div class="columns">
                      <!-- <div class="column is-1"></div> -->
                      <div class="column is-11 question-description">
                        Matrix Row
                        <b-tooltip
                          type="is-danger"
                          label="This field is required"
                        >
                          <span class="required">*</span>
                        </b-tooltip>
                      </div>
                    </div>
                    <span
                      v-for="(row, indexRow) in question.answerMatrixRows"
                      :key="indexRow"
                    >
                      <div class="columns">
                        <!-- <div class="column is-1"></div> -->
                        <div class="column is-6">
                          <b-input
                            v-model="row.description"
                            required
                            placeholder="Enter Row Name"
                          ></b-input>
                        </div>

                        <div class="column is-2 ">
                          <b-icon
                            pack="far"
                            icon="plus-square"
                            type="is-hcc-create-question"
                            size="is-medium"
                            @click.native="addAnswerRow(indexRow)"
                          ></b-icon>
                          <b-icon
                            pack="far"
                            icon="minus-square"
                            type="is-hcc-create-question"
                            size="is-medium"
                            @click.native="removeAnswerRow(indexRow)"
                            v-if="question.answerMatrixRows.length > 1"
                          ></b-icon>
                        </div>
                      </div>
                    </span>
                  </div>
                  <!-- matrix column -->
                  <div
                    class="column is-12"
                    v-if="question.questionType == 'matrix'"
                  >
                    <div class="columns">
                      <!-- <div class="column is-1"></div> -->
                      <div class="column is-11 question-description">
                        Matrix Column
                        <b-tooltip
                          type="is-danger"
                          label="This field is required"
                        >
                          <span class="required">*</span>
                        </b-tooltip>
                      </div>
                    </div>
                    <span
                      v-for="(column,
                      indexColumn) in question.answerMatrixColumns"
                      :key="indexColumn"
                    >
                      <div class="columns">
                        <!-- <div class="column is-1"> -->
                        <!-- <b-icon
                            icon="check-circle"
                            :type="column.point > 0 ? 'is-hcc-create-question' : 'is-dark'"
                            size="is-medium"
                            @click.native="
                              column.point > 0
                                ? (column.point = 0)
                                : (column.point = 1)
                            "
                          ></b-icon> -->
                        <!-- </div> -->

                        <div class="column is-6">
                          <b-input
                            v-model="column.description"
                            required
                            placeholder="Enter Column Name"
                          ></b-input>
                        </div>

                        <!-- <div class="column is-3 ">
                          <b-numberinput
                            controls-position="compact"
                            v-model="column.point"
                            type="is-hcc-create-question"
                            min="0"
                          ></b-numberinput>
                        </div> -->

                        <div class="column is-2 ">
                          <b-icon
                            pack="far"
                            icon="plus-square"
                            type="is-hcc-create-question"
                            size="is-medium"
                            @click.native="addAnswerColumn(indexColumn)"
                          ></b-icon>
                          <b-icon
                            pack="far"
                            icon="minus-square"
                            type="is-hcc-create-question"
                            size="is-medium"
                            @click.native="removeAnswerColumn(indexColumn)"
                            v-if="question.answerMatrixColumns.length > 1"
                          ></b-icon>
                        </div>
                      </div>
                    </span>
                  </div>

                  <!-- for ranking and checkbox max and min -->

                  <!-- min -->
                  <div
                    class="column is-2 question-description"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    Has Minimum?
                  </div>
                  <div
                    class="column is-1 question-description"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    <b-field>
                      <b-switch
                        v-model="question.answerOption.hasMinimum"
                        type="is-hcc-create-question"
                      ></b-switch>
                    </b-field>
                  </div>
                  <div
                    class="column is-3"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    <b-field>
                      <b-numberinput
                        v-model="question.answerOption.leftSideNumber"
                        v-if="question.answerOption.hasMinimum"
                        type="is-hcc-create-question"
                        min="1"
                        :max="question.answerOption.rightSideNumber"
                        controls-position="compact"
                      ></b-numberinput>
                    </b-field>
                  </div>
                  <div
                    class="column is-5"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  ></div>

                  <!-- max -->
                  <div
                    class="column is-2 question-description"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    Has Maximum?
                  </div>
                  <div
                    class="column is-1 question-description"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    <b-field>
                      <b-switch
                        v-model="question.answerOption.hasMaximum"
                        type="is-hcc-create-question"
                      ></b-switch>
                    </b-field>
                  </div>
                  <div
                    class="column is-3 "
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  >
                    <b-field>
                      <b-numberinput
                        v-model="question.answerOption.rightSideNumber"
                        v-if="question.answerOption.hasMaximum"
                        type="is-hcc-create-question"
                        min="1"
                        :max="question.answers.length"
                        controls-position="compact"
                      ></b-numberinput>
                    </b-field>
                  </div>
                  <div
                    class="column is-5"
                    v-if="
                      question.questionType == 'ranking' ||
                        question.questionType == 'checkbox'
                    "
                  ></div>

                  <!-- answer section -->
                  <div
                    class="column is-12"
                    v-if="
                      question.questionType != 'linear scale' &&
                        question.questionType != 'star rating' &&
                        question.questionType != 'matrix' &&
                        question.questionType != 'essay'
                    "
                  >
                    <span
                      v-for="(answer, indexAnswer) in question.answers"
                      :key="indexAnswer"
                    >
                      <div class="columns">
                        <div class="column is-1">
                          <b-icon
                            icon="check-circle"
                            :type="
                              answer.point > 0
                                ? 'is-hcc-create-question'
                                : 'is-dark'
                            "
                            size="is-medium"
                            @click.native="changeValid(indexAnswer)"
                            v-if="
                              question.questionType != 'essay' &&
                                question.questionType
                            "
                          ></b-icon>
                        </div>

                        <!-- ranking desc -->
                        <!-- <div
                          class="column is-6"
                          v-if="question.questionType == 'ranking'"
                        >
                          <b-field>
                            <b-input
                              v-model="answer.description"
                              required
                            ></b-input>
                          </b-field>
                        </div> -->

                        <div
                          class="column is-5"
                          v-if="
                            question.questionType == 'radio button' ||
                              question.questionType == 'checkbox' ||
                              question.questionType == 'ranking'
                          "
                        >
                          <!-- radio button or checkbox option -->
                          <!-- {{ answer.type }} -->
                          <b-field>
                            <b-select
                              v-model="answer.type"
                              expanded
                              placeholder="Select Type"
                            >
                              <option value="text">Text</option>
                              <option value="image">Image</option>
                            </b-select>
                          </b-field>
                        </div>

                        <!-- radio button or checkbox point -->
                        <div
                          class="column is-3 "
                          v-if="
                            question.questionType == 'radio button' ||
                              question.questionType == 'checkbox'
                          "
                        >
                          <b-numberinput
                            controls-position="compact"
                            v-model="answer.point"
                            type="is-hcc-create-question"
                            min="0"
                            @input="changeValidMultipleChoice(indexAnswer)"
                          ></b-numberinput>
                        </div>

                        <!-- ranking point -->
                        <div
                          class="column is-3 "
                          v-else-if="question.questionType == 'ranking'"
                        >
                          <b-numberinput
                            controls-position="compact"
                            v-model="answer.point"
                            type="is-hcc-create-question"
                            min="0"
                          ></b-numberinput>
                        </div>

                        <!-- ranking add answer -->
                        <div
                          class="column is-2 "
                          v-if="
                            question.questionType != 'radio button' &&
                              question.questionType != 'checkbox'
                          "
                        >
                          <div v-if="question.questionType == 'ranking'">
                            <b-icon
                              pack="far"
                              icon="plus-square"
                              type="is-hcc-create-question"
                              size="is-medium"
                              @click.native="addAnswer(indexAnswer)"
                            ></b-icon>
                            <b-icon
                              pack="far"
                              icon="minus-square"
                              type="is-hcc-create-question"
                              size="is-medium"
                              @click.native="removeAnswer(indexAnswer)"
                              v-if="question.answers.length > 1"
                            ></b-icon>
                          </div>
                        </div>

                        <!-- radio button and checkbox add answer -->
                        <div class="column is-5 question-description" v-else>
                          <b-icon
                            pack="far"
                            icon="plus-square"
                            type="is-hcc-create-question"
                            size="is-medium"
                            @click.native="addAnswer(indexAnswer)"
                          ></b-icon>
                          <b-icon
                            pack="far"
                            icon="minus-square"
                            type="is-hcc-create-question"
                            size="is-medium"
                            @click.native="removeAnswer(indexAnswer)"
                            v-if="question.answers.length > 1"
                          ></b-icon>
                        </div>
                      </div>

                      <!-- radio button checkbox add image / text -->
                      <div
                        class="columns"
                        v-if="
                          question.questionType == 'radio button' ||
                            question.questionType == 'checkbox' ||
                            question.questionType == 'ranking'
                        "
                      >
                        <div class="column is-1 "></div>
                        <div class="column is-5" v-if="answer.type == 'text'">
                          <b-input
                            v-model="answer.description"
                            placeholder="Enter The Answer"
                            required
                          ></b-input>
                        </div>
                        <div class="column" v-else>
                          <span>
                            <div class="columns is-multiline">
                              <div
                                class="column is-12"
                                v-if="answer.image.selectedImage"
                              >
                                <span v-show="false">
                                  {{ question.answers }}
                                </span>

                                <img
                                  :src="answer.image.selectedImage"
                                  style="max-height: 300px; max-width: 300px;"
                                  alt="Question Image"
                                />
                              </div>
                              <div class="column is-2">
                                <b-upload
                                  v-model="answer.image.uploadedImage"
                                  @input="getImageAnswer($event, indexAnswer)"
                                  accept="image/*"
                                  type="is-hcc-create-question"
                                >
                                  <a
                                    class="button is-hcc-create-question is-fullwidth"
                                  >
                                    <b-icon icon="upload"></b-icon>
                                    <span>Upload</span>
                                  </a>
                                </b-upload>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>

                      <!-- For Answer Tag -->
                      <!-- not essay tag -->
                      <div
                        class="columns"
                        v-if="
                          question.questionType == 'ranking' ||
                            question.questionType == 'checkbox' ||
                            question.questionType == 'radio button'
                        "
                      >
                        <div class="column is-1 "></div>
                        <div
                          class="column is-6"
                          v-if="question.questionType == 'ranking'"
                        >
                          <b-field>
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field
                                :type="errors.length > 0 ? 'is-danger' : null"
                              >
                                <b-taginput
                                  v-model="answer.newTags"
                                  :data="searchTags"
                                  :open-on-focus="false"
                                  :loading="isFetching"
                                  autocomplete
                                  placeholder="Enter The Tags"
                                  :allow-new="true"
                                  @typing="getTags"
                                >
                                  <template slot="empty">
                                    Press Enter to add new Tag
                                  </template>
                                </b-taginput>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column is-5" v-else>
                          <b-field>
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field
                                :type="errors.length > 0 ? 'is-danger' : null"
                              >
                                <b-taginput
                                  v-model="answer.newTags"
                                  :data="searchTags"
                                  :open-on-focus="false"
                                  :loading="isFetching"
                                  autocomplete
                                  placeholder="Enter The Tags"
                                  :allow-new="true"
                                  @typing="getTags"
                                >
                                  <template slot="empty">
                                    Press Enter to add new Tag
                                  </template>
                                </b-taginput>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                    </span>
                    <!-- <b-button type="is-text" @click="addMore(index)">Add more</b-button> -->
                  </div>

                  <div
                    class="column is-2 question-description"
                    v-if="
                      question.questionType == 'essay' &&
                        question.isExample == true
                    "
                  >
                    Has Answer?
                  </div>

                  <div
                    class="column is-10"
                    v-if="
                      question.questionType == 'essay' &&
                        question.isExample == true
                    "
                  >
                    <b-field>
                      <b-switch
                        v-model="question.hasAnswer"
                        type="is-hcc-create-question"
                      ></b-switch>
                    </b-field>
                  </div>

                  <!-- Answer section -->
                  <div class="answer" v-if="question.hasAnswer">
                    <div
                      class="column is-12 question-description"
                      v-if="
                        question.questionType == 'essay' &&
                          question.isExample == true
                      "
                    >
                      Answer
                    </div>

                    <!-- answer section -->
                    <div
                      class="column is-12"
                      v-if="
                        question.questionType != 'linear scale' &&
                          question.questionType != 'star rating' &&
                          question.questionType != 'matrix'
                      "
                    >
                      <span
                        v-for="(answer, indexAnswer) in question.answers"
                        :key="indexAnswer"
                      >
                        <div class="columns">
                          <div class="column is-1"></div>

                          <!-- essay desc -->
                          <div
                            class="column is-12"
                            v-if="
                              question.questionType == 'essay' &&
                                question.isExample == true
                            "
                          >
                            <div>
                              <b-input
                                v-model="answer.description"
                                type="textarea"
                                required
                              ></b-input>
                            </div>
                          </div>

                          <!-- essay add answer -->
                          <div
                            class="column is-2 "
                            v-if="
                              question.questionType != 'radio button' &&
                                question.questionType != 'checkbox'
                            "
                          >
                            <div
                              v-if="
                                question.questionType == 'essay' &&
                                  question.isExample == true
                              "
                            >
                              <b-icon
                                pack="far"
                                icon="plus-square"
                                type="is-hcc-create-question"
                                size="is-medium"
                                @click.native="addAnswer(indexAnswer)"
                              ></b-icon>
                              <b-icon
                                pack="far"
                                icon="minus-square"
                                type="is-hcc-create-question"
                                size="is-medium"
                                @click.native="removeAnswer(indexAnswer)"
                                v-if="question.answers.length > 1"
                              ></b-icon>
                            </div>
                          </div>
                        </div>

                        <!-- For Answer Tag -->
                        <!-- essay with example tag -->
                        <div
                          class="columns"
                          v-if="
                            question.questionType == 'essay' &&
                              question.isExample == true
                          "
                        >
                          <div class="column is-1 "></div>
                          <div class="column is-12">
                            <b-field>
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-field
                                  :type="errors.length > 0 ? 'is-danger' : null"
                                >
                                  <b-taginput
                                    v-model="answer.newTags"
                                    :data="searchTags"
                                    :open-on-focus="false"
                                    :loading="isFetching"
                                    autocomplete
                                    placeholder="Enter The Tags"
                                    :allow-new="true"
                                    @typing="getTags"
                                  >
                                    <template slot="empty">
                                      Press Enter to add new Tag
                                    </template>
                                  </b-taginput>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                          </div>
                        </div>
                      </span>
                      <!-- <b-button type="is-text" @click="addMore(index)">Add more</b-button> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- footer -->
              <div class="column is-12 bottomLine mb-4"></div>
              <div class="column is-3 verticalLine">
                <b-checkbox
                  v-model="question.isRandomAnswer"
                  type="is-hcc-create-question"
                >
                  Random Answer
                </b-checkbox>
              </div>
              <div class="column is-2">
                <b-field>
                  <b-switch
                    v-model="question.isActive"
                    type="is-hcc-create-question"
                  >
                    Active
                  </b-switch>
                </b-field>
              </div>
              <div class="column is-2">
                <b-field>
                  <b-switch
                    v-model="question.isRequired"
                    type="is-hcc-create-question"
                  >
                    Required
                  </b-switch>
                </b-field>
              </div>
              <div class="column is-5">
                <div class="button-wrapper" style="float:right">
                  <b-button @click="closeSideBar()">Cancel</b-button>
                  <b-button
                    class="is-hcc-create-question ml-4"
                    native-type="submit"
                    v-if="!questionId"
                  >
                    Add Question
                  </b-button>
                  <b-button
                    class="is-hcc-create-question ml-4"
                    native-type="submit"
                    v-else
                  >
                    Update Question
                  </b-button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'form-bank-soal',
  components: {
    videoPlayer,
  },
  props: {
    isOpen: Boolean,
    questionId: String,
    listEditedPhoto: {
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      open: this.isOpen,
      isFullheight: true,
      isOverlay: true,
      isRight: true,
      searchTags: [],
      isFetching: false,
      isFetchingCategory: false,
      data: {
        id: null,
        levels: [],
        categories: [],
        question: null,
        fileUrl: null,
      },
      question: {
        name: null,
        questionType: null,
        selectedCategory: [],
        selectedLevel: null,
        hasAnswer: false,
        question: null,
        hasImage: false,
        images: [{ id: null, selectedImage: null, uploadedImage: null }],
        hasVideo: false,
        videos: [
          {
            id: null,
            selectedVideo: null,
            uploadedVideo: null,
            maxPlayVideo: null,
          },
        ],
        isRandomAnswer: false,
        isExample: false,
        isRequired: false,
        isActive: false,
        newTags: [],
        answers: [
          {
            id: null,
            description: null,
            point: 0,
            newTags: [],
            isValid: false,
            type: 'text',
            image: { selectedImage: null, uploadedImage: null },
          },
        ],
        answerMatrixColumns: [
          {
            id: null,
            description: null,
            point: 0,
          },
        ],
        answerMatrixRows: [
          {
            id: null,
            description: null,
            point: 0,
          },
        ],
        answerOption: {
          hasMinimum: false,
          hasMaximum: false,
          leftSide: null,
          rightSide: null,
          leftSideNumber: 1,
          rightSideNumber: 2,
          isButton: false,
          trueAnswer: null,
          falseAnswer: null,
        },
      },

      editorConfig: {
        config1: {
          // The configuration of the editor.
          extraAllowedContent: 'div[class]',
          allowedContent: true,
          // filebrowserUploadMethod: "form",
          // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
          extraPlugins: 'justify,font',
        },
        config2: {
          // The configuration of the editor.
          extraAllowedContent: 'div[class]',
          allowedContent: true,
          // filebrowserUploadMethod: "form",
          // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
          extraPlugins: 'justify,font',
        },
        config3: {
          // The configuration of the editor.
          extraAllowedContent: 'div[class]',
          allowedContent: true,
          // filebrowserUploadMethod: "form",
          // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
          extraPlugins: 'justify,font',
        },
      },
      playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
      },
    }
  },
  watch: {
    isOpen() {
      this.open = this.isOpen
    },
  },
  created() {
    this.fetchLevels()
    if (this.$props.questionId) {
      this.fetchData(this.$props.questionId)
    }
  },
  methods: {
    ...mapActions({
      createQuestion: 'questionStorage/createQuestionStorage',
      updateQuestion: 'questionStorage/updateQuestionStorage',
      deleteAnswer: 'questionStorage/deleteQuestionStorageAnswer',
      deleteAnswerMatrix: 'questionStorage/deleteQuestionStorageAnswerMatrix',
      deleteQuestionStorageFile: 'questionStorage/deleteQuestionStorageFile',
      fetchQuestionStorage: 'questionStorage/fetchQuestionStorage',
      filterCategoryByName: 'newCategory/filterCategoryByName',
    }),
    playVideo(index) {
      this.$refs['video_' + index][0].player.play()
    },
    setOptions(src) {
      return {
        // videojs options
        muted: false,
        controls: false,
        width: 320,
        height: 240,
        language: 'en',
        sources: [
          {
            type: 'video/mp4',
            src: src,
          },
        ],
      }
    },
    fetchLevels() {
      this.$store
        .dispatch('newLevel/getAllLevel')
        .then(data => (this.data.levels = data.data))
    },

    async fetchData(id) {
      this.isLoading = true
      const response = await this.fetchQuestionStorage(id)
      // fetch data for question
      this.question.name = response.data.question.name
      this.question.questionType = response.data.question.questionType
      this.question.selectedCategory = [response.data.question.newCategory]
      this.data.categories = [response.data.question.newCategory]
      this.question.selectedLevel = response.data.question.newLevelId
      this.question.question = response.data.question.question
      // fetch data if linear scale
      if (response.data.question.questionType == 'linear scale') {
        this.question.answerOption = response.data.question
      }
      // fetch data if star rating
      else if (response.data.question.questionType == 'star rating') {
        this.question.answerOption = response.data.question
      }
      // fetch data if ranking
      else if (response.data.question.questionType == 'ranking') {
        if (response.data.question.leftSideNumber) {
          this.question.answerOption.hasMinimum = true
          this.question.answerOption.leftSideNumber =
            response.data.question.leftSideNumber
        }
        if (response.data.question.rightSideNumber) {
          this.question.answerOption.hasMaximum = true
          this.question.answerOption.rightSideNumber =
            response.data.question.rightSideNumber
        }
      }
      //fetch data if radio button or checkbox
      else if (
        response.data.question.questionType == 'radio button' ||
        response.data.question.questionType == 'checkbox'
      ) {
        if (response.data.question.isButton == 1) {
          this.question.answerOption.isButton = true
        } else {
          this.question.answerOption.isButton = false
        }
        if (response.data.question.isExample == 1) {
          this.question.answerOption.trueAnswer =
            response.data.question.trueAnswer
          this.question.answerOption.falseAnswer =
            response.data.question.falseAnswer
        }
        if (response.data.question.questionType == 'checkbox') {
          if (response.data.question.leftSideNumber) {
            this.question.answerOption.hasMinimum = true
            this.question.answerOption.leftSideNumber =
              response.data.question.leftSideNumber
          }
          if (response.data.question.rightSideNumber) {
            this.question.answerOption.hasMaximum = true
            this.question.answerOption.rightSideNumber =
              response.data.question.rightSideNumber
          }
        }
      }

      if (response.data.question.hasAnswer == 1) {
        this.question.hasAnswer = true
      }
      if (response.data.question.isRandomAnswer == 1) {
        this.question.isRandomAnswer = true
      }
      if (response.data.question.isExample == 1) {
        this.question.isExample = true
      }
      if (response.data.question.isRequired == 1) {
        this.question.isRequired = true
      }
      if (!response.data.question.deletedAt) {
        this.question.isActive = true
      }
      if (response.data.questionVideo.length > 0) {
        for (var vid in response.data.questionVideo) {
          this.question.videos = response.data.questionVideo
          this.question.hasVideo = true
          this.question.videos[vid]['uploadedVideo'] = null
          //rename key in videos
          this.question.videos[vid]['selectedVideo'] = this.question.videos[
            vid
          ]['filePath']
          // for load video
          this.question.videos[vid].selectedVideo =
            process.env.VUE_APP_BACKEND_URL +
            response.data.questionVideo[vid].filePath
        }
      }
      if (response.data.questionImage.length > 0) {
        for (var img in response.data.questionImage) {
          this.question.images = response.data.questionImage
          this.question.hasImage = true
          this.question.images[img]['uploadedImage'] = null
          //rename key in images
          this.question.images[img]['selectedImage'] = this.question.images[
            img
          ]['filePath']
          // for load image
          this.question.images[img].selectedImage =
            process.env.VUE_APP_BACKEND_URL +
            response.data.questionImage[img].filePath
        }
      }

      //fetch tags data
      for (var x in response.data.question.newTags) {
        this.question.newTags.push(response.data.question.newTags[x].name)
      }

      //fetch data for answer
      if (
        response.data.question.hasAnswer == 0 &&
        response.data.question.questionType == 'essay'
      ) {
        this.question.answers = response.data.answer
        for (var m in response.data.answer) {
          for (var n in this.question.answers[m].newTags) {
            this.question.answers[m].newTags[n] = this.question.answers[
              m
            ].newTags[n].name
          }
        }
      }
      //  else if (
      //   response.data.question.hasAnswer == 0 &&
      //   response.data.question.questionType == 'ranking'
      // ) {
      //   this.question.answers = response.data.answer
      //   for (var r in response.data.answer) {
      //     for (var s in this.question.answers[r].newTags) {
      //       this.question.answers[r].newTags[s] = this.question.answers[
      //         r
      //       ].newTags[s].name
      //     }
      //   }
      // }
      else if (
        response.data.question.hasAnswer == 0 &&
        (response.data.question.questionType == 'radio button' ||
          response.data.question.questionType == 'ranking')
      ) {
        this.question.answers = response.data.answer
        for (var rb in response.data.answer) {
          for (var rbb in this.question.answers[rb].newTags) {
            this.question.answers[rb].newTags[rbb] = this.question.answers[
              rb
            ].newTags[rbb].name
          }
          if (this.question.answers[rb].isValid == 1) {
            this.question.answers[rb].isValid = true
          } else {
            this.question.answers[rb].isValid = false
          }

          //if answer type image
          if (this.question.answers[rb].type == 'image') {
            //add image json in answera array
            this.question.answers[rb]['image'] = {
              selectedImage: null,
              uploadedImage: null,
            }
            //rename key in images
            this.question.answers[rb].image[
              'selectedImage'
            ] = this.question.answers[rb].imagePath
            // for load image
            this.question.answers[rb].image.selectedImage =
              process.env.VUE_APP_BACKEND_URL +
              this.question.answers[rb].imagePath
          } else {
            this.question.answers[rb]['image'] = {
              selectedImage: null,
              uploadedImage: null,
            }
          }
        }
      } else if (
        response.data.question.hasAnswer == 0 &&
        response.data.question.questionType == 'checkbox'
      ) {
        this.question.answers = response.data.answer
        for (var c in response.data.answer) {
          for (var cb in this.question.answers[c].newTags) {
            this.question.answers[c].newTags[cb] = this.question.answers[
              c
            ].newTags[cb].name
          }
          if (this.question.answers[c].isValid == 1) {
            this.question.answers[c].isValid = true
          } else {
            this.question.answers[c].isValid = false
          }

          //if answer type image
          if (this.question.answers[c].type == 'image') {
            //add image json in answera array
            this.question.answers[c]['image'] = {
              selectedImage: null,
              uploadedImage: null,
            }
            //rename key in images
            this.question.answers[c].image[
              'selectedImage'
            ] = this.question.answers[c].imagePath
            // for load image
            this.question.answers[c].image.selectedImage =
              process.env.VUE_APP_BACKEND_URL +
              this.question.answers[c].imagePath
          } else {
            this.question.answers[c]['image'] = {
              selectedImage: null,
              uploadedImage: null,
            }
          }
        }
      } else if (response.data.question.questionType == 'matrix') {
        this.question.answerMatrixRows = []
        this.question.answerMatrixColumns = []
        var indexRow = 0
        var indexColumn = 0
        for (var mt in response.data.question.newMatrix) {
          if (response.data.question.newMatrix[mt].row > 1) {
            this.question.answerMatrixRows.push(
              response.data.question.newMatrix[mt]
            )
            this.question.answerMatrixRows[indexRow][
              'description'
            ] = this.question.answerMatrixRows[indexRow]['value']
            indexRow++
          } else if (response.data.question.newMatrix[mt].column > 1) {
            this.question.answerMatrixColumns.push(
              response.data.question.newMatrix[mt]
            )
            this.question.answerMatrixColumns[indexColumn][
              'description'
            ] = this.question.answerMatrixColumns[indexColumn]['value']
            indexColumn++
          }
        }
      } else if (response.data.question.hasAnswer == 1) {
        this.question.answers = response.data.answer
        for (var y in response.data.answer) {
          for (var z in this.question.answers[y].newTags) {
            this.question.answers[y].newTags[z] = this.question.answers[
              y
            ].newTags[z].name
          }
          if (this.question.answers[y].isValid == 1) {
            this.question.answers[y].isValid = true
          } else {
            this.question.answers[y].isValid = false
          }

          //if answer type image
          if (this.question.answers[y].type == 'image') {
            //add image json in answera array
            this.question.answers[y]['image'] = {
              selectedImage: null,
              uploadedImage: null,
            }
            //rename key in images
            this.question.answers[y].image[
              'selectedImage'
            ] = this.question.answers[y].imagePath
            // for load image
            this.question.answers[y].image.selectedImage =
              process.env.VUE_APP_BACKEND_URL +
              this.question.answers[y].imagePath
          }
        }
      }

      this.isLoading = false
    },

    getImage(e, index) {
      let image = e
      let reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.question.images[index].selectedImage = e.target.result
        this.question.images[index].createdAt = e.target.result
      }
    },

    getVideo(e, index) {
      let video = e
      let reader = new FileReader()
      reader.readAsDataURL(video)
      reader.onload = e => {
        this.question.videos[index].createdAt = e.target.result
        this.question.videos[index].selectedVideo = e.target.result
      }
    },

    getImageAnswer(e, index) {
      let image = e
      let reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.question.answers[index].image.selectedImage = e.target.result
        this.question.answers[index].imagePath = e.target.result
      }
    },

    confirmSave() {
      if (this.$props.questionId) {
        this.$buefy.dialog.confirm({
          message: 'Save changes?',
          confirmText: 'Ya',
          onConfirm: () => this.onUpdateClicked(),
        })
      } else {
        this.$buefy.dialog.confirm({
          message: 'Add this question?',
          confirmText: 'Ya',
          onConfirm: () => this.onSaveClicked(),
        })
      }
    },

    async onSaveClicked() {
      this.isLoading = true
      let formData = new FormData()

      if (this.question.hasImage) {
        // append image to form data
        for (var x in this.question.images) {
          formData.append('image' + x, this.question.images[x].uploadedImage)
        }
      }
      if (this.question.hasVideo) {
        // append video to form data
        for (var y in this.question.videos) {
          formData.append('video' + y, this.question.videos[y].uploadedVideo)
        }
      }
      if (
        this.question.questionType == 'radio button' ||
        this.question.questionType == 'checkbox' ||
        this.question.questionType == 'ranking'
      ) {
        for (var z in this.question.answers) {
          if (this.question.answers[z].type == 'image') {
            formData.append(
              'answerImage' + z,
              this.question.answers[z].image.uploadedImage
            )
          }
        }
      }
      formData.append('data', JSON.stringify(this.question))
      try {
        await this.createQuestion(formData)
        this.success('Berhasil Membuat Pertanyaan')
        ;(this.question = {
          name: null,
          questionType: null,
          selectedCategory: null,
          selectedLevel: null,
          hasAnswer: false,
          question: '',
          hasImage: false,
          images: [{ id: null, selectedImage: null, uploadedImage: null }],
          hasVideo: false,
          videos: [
            {
              id: null,
              selectedVideo: null,
              uploadedVideo: null,
              maxPlayVideo: null,
            },
          ],
          isRandomAnswer: false,
          isExample: false,
          isRequired: false,
          isActive: false,
          newTags: [],
          answers: [
            {
              id: null,
              description: null,
              point: 0,
              newTags: [],
              isValid: false,
              type: 'text',
              image: { selectedImage: null, uploadedImage: null },
            },
          ],
          answerMatrixColumns: [
            {
              id: null,
              description: null,
              point: 0,
            },
          ],
          answerMatrixRows: [
            {
              id: null,
              description: null,
              point: 0,
            },
          ],
          answerOption: {
            hasMinimum: false,
            hasMaximum: false,
            leftSide: null,
            rightSide: null,
            leftSideNumber: 1,
            rightSideNumber: 2,
            isButton: false,
          },
        }),
          this.$buefy.dialog.confirm({
            message: 'Tambah Pertanyaan Lagi?',
            cancelText: 'Tidak',
            onCancel: () => this.closeSideBar(),
            confirmText: 'Ya',
            onConfirm: () => {},
          })
      } catch (err) {
        this.danger(err.response.data.message)
      }
      this.isLoading = false
    },

    async onUpdateClicked() {
      this.isLoading = true
      let id = this.$props.questionId
      // append image to form data
      let formData = new FormData()
      //for image
      var changeImage = []
      if (this.question.hasImage) {
        // append image to form data
        for (var x in this.question.images) {
          //check if image chage
          if (this.question.images[x].uploadedImage) {
            formData.append('image' + x, this.question.images[x].uploadedImage)
            changeImage.push({
              indexImage: x,
              imageId: this.question.images[x].id,
            })
          }
        }
      }
      //for answer image
      if (
        this.question.questionType == 'radio button' ||
        this.question.questionType == 'checkbox' ||
        this.question.questionType == 'ranking'
      ) {
        for (var z in this.question.answers) {
          if (this.question.answers[z].type == 'image') {
            if (this.question.answers[z].image.uploadedImage) {
              formData.append(
                'answerImage' + z,
                this.question.answers[z].image.uploadedImage
              )
            }
          }
        }
      }

      var changeVideo = []
      //for video
      if (this.question.hasVideo) {
        // append video to form data
        for (var y in this.question.videos) {
          //check if video change
          if (this.question.videos[y].uploadedVideo) {
            formData.append('video' + y, this.question.videos[y].uploadedVideo)
            changeVideo.push({
              indexVideo: y,
              videoId: this.question.videos[y].id,
            })
          }
        }
      }
      formData.append('changeImage', JSON.stringify(changeImage))
      formData.append('changeVideo', JSON.stringify(changeVideo))

      formData.append('data', JSON.stringify(this.question))
      try {
        await this.updateQuestion({ formData, id })
        if (this.questionDataResponse.status == 500) {
          this.danger(this.questionDataResponse.message)
        } else {
          this.success('Perubahan Berhasil')
        }
        this.closeSideBar()
      } catch (err) {
        this.danger(err.response.data.message)
      }
      this.isLoading = false
    },

    async getCategory(event) {
      let data = {
        search: event,
      }

      this.isFetchingCategory = true
      const response = await this.filterCategoryByName(data)

      this.data.categories = response.data
      this.isFetchingCategory = false
    },

    getTags: debounce(function(search) {
      this.isFetching = true
      this.$store.dispatch('newTag/searchTags', search).then(data => {
        if (!data) {
          this.searchTags = []
        } else {
          this.searchTags = []
          data.data.forEach(element => {
            this.searchTags.push(element.name)
          })
        }
        this.isFetching = false
      })
    }, 500),

    closeSideBar() {
      this.$emit('close', false)
      this.open = false
    },

    addAnswer(indexAnswer) {
      this.question.answers.splice(indexAnswer + 1, 0, {
        id: null,
        description: null,
        point: 0,
        newTags: [],
        isValid: false,
        type: 'text',
        image: { selectedImage: null, uploadedImage: null },
      })
    },
    async removeAnswer(indexAnswer) {
      if (this.question.answers[indexAnswer].id) {
        this.isLoading = true
        try {
          await this.deleteAnswer(this.question.answers[indexAnswer].id)
          if (this.questionDataResponse.status == 500) {
            this.danger(this.questionDataResponse.message)
          } else {
            this.question.answers.splice(indexAnswer, 1)
          }
        } catch (err) {
          this.danger(err)
        }
        this.isLoading = false
      } else {
        this.question.answers.splice(indexAnswer, 1)
      }
    },

    addImage(indexImage) {
      this.question.images.splice(indexImage + 1, 0, {
        id: null,
        selectedImage: null,
        uploadedImage: null,
      })
    },
    async removeImage(indexImage) {
      if (this.question.images[indexImage].id) {
        this.isLoading = true
        try {
          await this.deleteQuestionStorageFile(
            this.question.images[indexImage].id
          )
          if (this.questionDataResponse.status == 500) {
            this.danger(this.questionDataResponse.message)
          } else {
            this.question.images.splice(indexImage, 1)
          }
        } catch (err) {
          this.danger(err)
        }
        this.isLoading = false
      } else {
        this.question.images.splice(indexImage, 1)
      }
    },

    addVideo(indexVideo) {
      this.question.videos.splice(indexVideo + 1, 0, {
        id: null,
        selectedVideo: null,
        uploadedVideo: null,
      })
    },
    async removeVideo(indexVideo) {
      if (this.question.videos[indexVideo].id) {
        this.isLoading = true
        try {
          await this.deleteQuestionStorageFile(
            this.question.videos[indexVideo].id
          )
          if (this.questionDataResponse.status == 500) {
            this.danger(this.questionDataResponse.message)
          } else {
            this.question.videos.splice(indexVideo, 1)
          }
        } catch (err) {
          this.danger(err)
        }
        this.isLoading = false
      } else {
        this.question.videos.splice(indexVideo, 1)
      }
    },

    addAnswerColumn(indexColumn) {
      this.question.answerMatrixColumns.splice(indexColumn + 1, 0, {
        id: null,
        description: null,
        point: 0,
      })
    },

    async removeAnswerColumn(indexColumn) {
      if (this.question.answerMatrixColumns[indexColumn].id) {
        this.isLoading = true
        try {
          await this.deleteAnswerMatrix(
            this.question.answerMatrixColumns[indexColumn].id
          )
          if (this.questionDataResponse.status == 500) {
            this.danger(this.questionDataResponse.message)
          } else {
            this.question.answerMatrixColumns.splice(indexColumn, 1)
          }
        } catch (err) {
          this.danger(err)
        }
        this.isLoading = false
      } else {
        this.question.answerMatrixColumns.splice(indexColumn, 1)
      }
    },

    addAnswerRow(indexRow) {
      this.question.answerMatrixRows.splice(indexRow + 1, 0, {
        id: null,
        description: null,
        point: 0,
      })
    },

    async removeAnswerRow(indexRow) {
      if (this.question.answerMatrixRows[indexRow].id) {
        this.isLoading = true
        try {
          await this.deleteAnswerMatrix(
            this.question.answerMatrixRows[indexRow].id
          )
          if (this.questionDataResponse.status == 500) {
            this.danger(this.questionDataResponse.message)
          } else {
            this.question.answerMatrixRows.splice(indexRow, 1)
          }
        } catch (err) {
          this.danger(err)
        }
        this.isLoading = false
      } else {
        this.question.answerMatrixRows.splice(indexRow, 1)
      }
    },

    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    closeAnswer() {
      if (this.question.questionType == 'essay') {
        this.question.hasAnswer = false
      }
    },
    changeValid(index) {
      if (this.question.questionType == 'radio button') {
        for (var x in this.question.answers) {
          if (parseInt(x) != index) {
            this.question.answers[x].isValid = false
            this.question.answers[x].point = 0
          } else {
            if (this.question.answers[x].point == 0) {
              this.question.answers[x].isValid = true
              this.question.answers[x].point = 1
            } else {
              this.question.answers[x].isValid = false
              this.question.answers[x].point = 0
            }
          }
        }
      } else if (this.question.answers[index].point > 0) {
        this.question.answers[index].point = 0
        this.question.answers[index].isValid = false
      } else {
        this.question.answers[index].point = 1
        this.question.answers[index].isValid = true
      }
    },
    changeValidMultipleChoice(index) {
      if (this.question.questionType == 'radio button') {
        for (var x in this.question.answers) {
          if (parseInt(x) != index) {
            this.question.answers[x].isValid = false
            this.question.answers[x].point = 0
          } else {
            if (this.question.answers[x].point == 0) {
              this.question.answers[x].isValid = false
            } else {
              this.question.answers[x].isValid = true
            }
          }
        }
      } else {
        for (var y in this.question.answers) {
          if (this.question.answers[y].point == 0) {
            this.question.answers[y].isValid = false
          } else {
            this.question.answers[y].isValid = true
          }
        }
      }
    },
    changeStar() {
      if (
        this.question.answerOption.leftSideNumber >
        this.question.answerOption.rightSideNumber
      ) {
        this.question.answerOption.leftSideNumber = this.question.answerOption.rightSideNumber
      }
    },
  },
  computed: {
    ...mapGetters({
      questionDataResponse: 'questionStorage/getQuestionDataResponse',
    }),
  },
}
</script>

<style lang="scss">
.b-sidebar .sidebar-content.is-fixed.is-right {
  /*width: 50%;*/
  width: 1027px;
  border: 1px solid whitesmoke;
}
.form {
  padding-left: 10px !important;

  &-title {
    color: #000000 !important;
    font-size: 24px !important;
    font-weight: 900 !important;
  }
}

.question-description {
  font-weight: bolder;
  font-size: 14px;
  margin-top: 0.5rem;
  color: #000000 !important;
}

.verticalLine {
  border-right: medium solid #dbdbdb;
}

.bottomLine {
  border-bottom: medium solid #dbdbdb;
}

button.button.is-primary {
  background-color: #00b79f;
}

.form-title {
  font-family: 'Nunito Sans', Arial;
  font-weight: 900;
}

.button-close-top {
  font-family: 'Nunito Sans', Arial;
  font-weight: 600;
}

.select select::placeholder,
.taginput .taginput-container.is-focusable::placeholder,
.textarea::placeholder,
.input::placeholder {
  color: black;
  opacity: 0.5;
}
element {
}
.field.has-addons .control:not(:first-child):not(:last-child) .button,
.field.has-addons .control:not(:first-child):not(:last-child) .input,
.field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}
.b-numberinput input[type='number'] {
  min-width: 2em;
}
video::-webkit-media-controls {
  display: none;
}

/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-play-button {
}

video::-webkit-media-controls-volume-slider {
}

video::-webkit-media-controls-mute-button {
}

video::-webkit-media-controls-timeline {
}

video::-webkit-media-controls-current-time-display {
}
</style>
